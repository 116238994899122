/**
 * Sign Up Virtual Care page
 */
/* Import section */
import { graphql, Link } from "gatsby"
import React from "react"
import Iframesignup from "../../components/iframesignup"
import Layoutwithoutfooter from "../../components/layoutwithoutfooter"
import Seo from "../../components/seo"

/* Page function declaration */
const SignupPage = ({ data }) => {

  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageSignup

  return (
    <Layoutwithoutfooter className="signup-vc">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        classHtml="overflowhtml"
        classbody="overflow no-cookies"
      />

      <h1>{dataAcf.titleGeneralSignup}</h1>
      <h2>Trying to create an account linked to your practice or doctor? Contact support to request your activation key <Link to="/contact/#contact-form">here</Link></h2>

      {/* Iframe content */}
      <section className="section-signupvc iframe-section">
        <Iframesignup />
      </section>

    </Layoutwithoutfooter>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "sign-up"}) {
      acfPageSignup {
        titleGeneralSignup
        contentGeneralSignup
      }
      seo {
        metaDesc
        title
      }
    }
  }
`
/* Export page informations */
export default SignupPage



