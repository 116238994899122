/**
 * Const element
 * Iframe Sign Up component
 * Display the iframe of Sign Up Virtual Care
 */
/* Import section */
import React from "react"

/* Declaration function */
const Iframesignup= () => {
  /**VERSION ON DEV */
  //var src = 'https://dev27.myca.io/hhweb/apps/authentication/#signUpVirtualCarePortalConnect'

  /** VERSION ON PROD */
  var src = 'https://platform.hellohealth.com/hhweb/apps/authentication/#signUpVirtualCarePortalConnect'

  return (
    <iframe className="iframe-signupvc" name="signUpvc" src={src} title="sign-up-vc"></iframe>
  )
}

/* Export function */
export default Iframesignup
